import React, { useState } from "react";
import styled from "styled-components";

const PublicHealth = () => {
  const [readMore, setReadMore] = useState(false);

  return (
    <StyledPublicHealth>
      <TextWrapper>
        <H3>
          Medad Technology's Ultrasonic Delivery System is built on 3
          proprietary electronics and an algorithm, for 3 key product
          applications, with design scalability.
        </H3>
        <Description>
          <P>
            Our core technology works under the Resonant Drive System (RDS)
            method. This incorporates a natural frequency-finding electronics
            system for which, a complete set of proprietary ASICs and an
            algorithm has been developed. The algorithm works seamlessly with
            the electronics to form a feedback loop that looks for the resonant
            point at the start of each inhalation, for optimal aerosol delivery
            efficiency. The closed-system consists of a Bluetooth enabled
            device, disposable pod and mobile app which is available on the App
            Store and Google Play. The app monitors usage data such as number of
            inhalations and pod consumption level.
          </P>
          <ShowMoreButton showMore={readMore} onClick={() => setReadMore(true)}>
            Show more
          </ShowMoreButton>
          <ShowMore showMore={readMore}>
            <P>
              The closed-system consists of a Bluetooth enabled device,
              disposable pod and mobile app which is available on the App Store
              and Google Play. The app monitors usage data such as number of
              inhalations and pod consumption level.
            </P>
          </ShowMore>
        </Description>
      </TextWrapper>
    </StyledPublicHealth>
  );
};

export default PublicHealth;

const StyledPublicHealth = styled.div`
  padding: 9.4rem 20rem;
  @media ${({ theme }) => theme.breakpoints.mobile} {
    padding: 6rem 2.4rem;
    background: rgb(255, 255, 255);
    background: linear-gradient(
      180deg,
      rgba(255, 255, 255, 1) 0%,
      rgba(149, 144, 217, 0.3) 0%,
      rgba(255, 255, 255, 0.05) 62%
    );
  }
  @media ${({ theme }) => theme.breakpoints.small_height} {
    padding: 9.4rem 15vw;
  }
`;

const TextWrapper = styled.div`
  margin-top: 4rem;
  display: flex;
  flex-direction: column;
`;

const ShowMoreButton = styled.button`
  margin-top: 2rem;
  display: none;
  text-decoration: none;
  border: none;
  background: none;
  color: #3b869d;
  font-family: Sequel45;
  font-size: 1.6rem;
  line-height: 2.2rem;
  @media ${({ theme }) => theme.breakpoints.mobile} {
    display: ${({ showMore }) => (showMore ? "none" : "flex")};
  }
`;

const ShowMore = styled.div`
  @media ${({ theme }) => theme.breakpoints.mobile} {
    display: ${({ showMore }) => (showMore ? "block" : "none")};
  }
`;

const H3 = styled.h3`
  font-family: Sequel75;
  font-size: 3.2rem;
  line-height: 4.4rem;
  font-weight: 400;
  @media ${({ theme }) => theme.breakpoints.mobile} {
    font-size: 2rem;
    line-height: 2.8rem;
  }
`;

const Description = styled.div`
  display: flex;
  flex-direction: column;
  margin-top: 4rem;
  @media ${({ theme }) => theme.breakpoints.mobile} {
    margin-top: 3rem;
  }
`;

const P = styled.p`
  font-family: Sequel45;
  font-weight: 400;
  font-size: 2rem;
  line-height: 2.7rem;

  @media ${({ theme }) => theme.breakpoints.small_height} {
    font-size: 1.8rem;
  }

  &:last-child {
    margin-top: 3rem;
  }

  @media ${({ theme }) => theme.breakpoints.mobile} {
    font-size: 1.8rem;
    line-height: 2.2rem;
    &:last-child {
      margin-top: 2rem;
    }
  }
`;
