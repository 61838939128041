import React from "react";
import styled from "styled-components";

import img1 from "../../../../static/images/whatwebuild-page/categories/images/1.png";
// import img2 from "../../../../static/images/whatwebuild-page/categories/images/2.jpg";
// import img3 from "../../../../static/images/whatwebuild-page/categories/images/3.png";
// import img4 from "../../../../static/images/whatwebuild-page/categories/images/4.png";
// import img5 from "../../../../static/images/whatwebuild-page/categories/images/5.png";
// import img6 from "../../../../static/images/whatwebuild-page/categories/images/6.png";

import ultrasounds from "../../../../static/images/whatwebuild-page/hero/images/ultrasounds.png";
import usps1 from "../../../../static/images/whatwebuild-page/hero/images/usps1.png";
import usps2 from "../../../../static/images/whatwebuild-page/hero/images/usps2.png";

const Categories = () => {
  return (
    <StyledCategories>
      {/* Images */}
      <DirectionFirst>
        <VisualAssistanceSmallImageRight img={ultrasounds} />
        <DirectionHorizontal>
          {/* <VisualAssistanceSmallImageLeft img={usps1} />
          <VisualAssistanceSmallImageLeft img={usps2} /> */}

          <TextWrapperUSPs>
            <H3>Nicotine Delivery</H3>
            <P>
              <ul>
                <li>
                  Aerosolization with ultrasound prevents thermal degradation
                </li>
                <li>No toxic carbonyls that cause smoking-related diseases</li>
                <li>
                  Quick relief of nicotine cravings with fine particle size for
                  optimal absorption and systemic delivery
                </li>
                <li>
                  Anti-counterfeit mechanism to tackle risk of use of illicit
                  products
                </li>
                <li>
                  Data & monitoring for behavioural therapy support through
                  mobile app, and healthcare professional connectivity
                </li>
              </ul>
            </P>
            <br />
            <br />
            <br />
            <H3>Drug Delivery</H3>
            <P>
              <ul>
                <li>
                  Fine particle size for deeper lung reach to the alveoli for
                  systemic exposure
                </li>
                <li>
                  Increased efficiency with 90% + absorption, as it bypasses the
                  effects of 1st & 2nd metabolism
                </li>
                <li>
                  Reduced wastage due to intact delivery of drug product,
                  facilitating potential cost reduction
                </li>
                <li>
                  Wider application of potential use for treatment of disease
                  outside of the respiratory tract
                </li>
                <li>
                  Proprietary ICs and feedback control algorithm that can adjust
                  particle size for optimal delivery across a variety of drug
                  solutions
                </li>
              </ul>
            </P>
          </TextWrapperUSPs>
        </DirectionHorizontal>
      </DirectionFirst>
      <Gap></Gap>
      <Direction reverse={false}>
        <VisualAssistance img={img1} />
        <Description>
          <TextWrapper>
            <H3>Powerful New-Gen ASICs</H3>
            <P>
              Our proprietary ASICs offer enhanced functionality and operational
              flexibility in comparison to off-the-shelf components. This,
              combined with our algorithm, has allowed us to overcome the
              limitations of aerosolization faced by existing inhalation
              technologies.
              <br />
              <br />
              Our full H-Bridge has a wider adjustable frequency range which
              provides more precise operation of piezo-ceramic transducers.
              <br />
              <br />
              Our Oscillator ASIC not only drives the H-Bridge but also
              integrates enhanced functionalities for sensing, monitoring,
              efficient battery charging, LED driving, and external controls.
              <br />
              <br />
              The disposable pod is authenticated using one-time-programmable
              (OTP) PCB which stores vital pod information such as drug
              characteristics and can also be used as an anti-counterfeit
              mechanism.
            </P>
          </TextWrapper>
        </Description>
      </Direction>
      {/* <Direction reverse>
        <VisualAssistance img={img2} />
        <Description>
          <TextWrapper>
            <H3>Inhalation Ultrasound Technology</H3>
            <P>
              Medad Technology built a miniaturized hand-held nebulizer with a
              suite of companion integrated chips capable of operating
              ultrasound transducers at high frequencies with feedback control
              algorithms that finally breaks the limit of aerosolizing dense and
              viscous solutions – a disruption of both the medical and
              recreational liquid delivery systems through inhalation.
            </P>
          </TextWrapper>
        </Description>
      </Direction> */}
      {/* <Direction reverse={false}>
        <VisualAssistance img={img3} />
        <Description>
          <TextWrapper>
            <H3>Precision Ultrasonic Cell Lysing</H3>
            <P>
              After breakthroughs in ultrasound inhalation technology, Medad
              Technology used the same concepts to develop an ultrasonic cell
              lysing technique by developing a proprietary Power Management
              Integrated Circuit (PMIC) that controls the frequency and power of
              the sound wave used to break down the cell wall during the
              extraction process.
              <br />
              Our ultrasonic cell lysing registered high precision, high speed
              and less destructive results than other means.
            </P>
          </TextWrapper>
        </Description>
      </Direction> */}
      {/* <Direction reverse>
        <VisualAssistance img={img5} />
        <Description>
          <TextWrapper>
            <H3>Thermal Cycling Chip for PCR Tests</H3>
            <P>
              Medad Technology harnessed its breakthroughs in ICs design to
              create a PCR thermal chip with a cycle time of just one second per
              cycle.
            </P>
          </TextWrapper>
        </Description>
      </Direction> */}
      {/* <Direction reverse={false}>
        <VisualAssistance img={img6} />
        <Description>
          <TextWrapper>
            <H3>
              New Generation Battery Power Management for Electrical Vehicles
            </H3>
            <P>
              Medad Technology is joining the effort to reduce global emissions
              through the use of electric cars by developing a new generation of
              wirelessly charged electric vehicle batteries. Our ICs can assess
              and control the power flow and consumption of each battery cell.
            </P>
          </TextWrapper>
        </Description>
      </Direction> */}
    </StyledCategories>
  );
};

export default Categories;

const StyledCategories = styled.div`
  margin-bottom: 14.4rem;
  @media ${({ theme }) => theme.breakpoints.mobile} {
    margin-bottom: 1rem;
  }
`;

const Gap = styled.div`
  margin-bottom: 14.4rem;
`;

const Direction = styled.div`
  min-height: calc(100vh - ${({ theme }) => theme.sizes.header});
  display: flex;
  justify-content: center;
  flex-direction: ${(props) => (props.reverse ? "row-reverse" : "row")};
  // margin-top: ${(props) => (props.reverse ? "0" : "14.4rem")};
  @media ${({ theme }) => theme.breakpoints.mobile} {
    min-height: unset;
    flex-direction: column;
  }
`;

const DirectionFirst = styled.div`
  display: flex;
  gap: 100px;
  justify-content: center;
  flex-direction: ${(props) => (props.reverse ? "row-reverse" : "row")};
  @media ${({ theme }) => theme.breakpoints.mobile} {
    min-height: unset;
    flex-direction: column;
  }
`;

const DirectionHorizontal = styled.div`
  min-height: 600px;
  display: flex;
  flex-direction: column;
  flex: 1;
`;

const VisualAssistance = styled.div`
  flex: 1;
  background-image: url(${(props) => props.img});
  background-position: center;
  background-size: cover;
  background-repeat: no-repeat;
  @media ${({ theme }) => theme.breakpoints.mobile} {
    flex: unset;
    width: 100%;
    height: 50vh;
  }
`;

const VisualAssistanceSmallImageLeft = styled.div`
  flex: 1;
  background-image: url(${(props) => props.img});
  background-position: center left;
  background-size: 500px;
  background-repeat: no-repeat;
  @media ${({ theme }) => theme.breakpoints.mobile} {
    flex: unset;
    width: 100%;
    height: 50vh;
    background-size: contain;
  }
`;

const VisualAssistanceSmallImageRight = styled.div`
  flex: 1;
  background-image: url(${(props) => props.img});
  background-position: center right;
  background-size: 650px;
  background-repeat: no-repeat;
  @media ${({ theme }) => theme.breakpoints.mobile} {
    flex: unset;
    width: 100%;
    height: 50vh;
    background-size: contain;
  }
`;

const Description = styled.div`
  flex: 1;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  //padding: 0 12rem;
  @media ${({ theme }) => theme.breakpoints.mobile} {
    //padding: 3rem 2.4rem;
    flex: unset;
    //min-height: 50vh;
  }
`;

const TextWrapper = styled.div`
  padding: 0 12rem;
  @media ${({ theme }) => theme.breakpoints.mobile} {
    padding: 3rem 2.4rem 4rem 2.4rem;
  }
`;

const TextWrapperUSPs = styled.div`
  padding-right: 12rem;
  @media ${({ theme }) => theme.breakpoints.mobile} {
    padding: 3rem 2.4rem 4rem 2.4rem;
  }
`;

const H3 = styled.h3`
  font-family: Sequel75;
  font-size: 3.2rem;
  line-height: 4.4rem;
  @media ${({ theme }) => theme.breakpoints.mobile} {
    font-family: Sequel45;
    font-size: 2rem;
    line-height: 2.8rem;
  }
  @media ${({ theme }) => theme.breakpoints.small_height} {
    font-family: Sequel65;
    font-size: 3rem;
  }
`;

const P = styled.p`
  margin-top: 4rem;
  font-family: Sequel45;
  font-weight: 400;
  font-size: 2rem;
  line-height: 2.8rem;
  @media ${({ theme }) => theme.breakpoints.mobile} {
    margin-top: 2rem;
    font-size: 1.6rem;
    line-height: 2.2rem;
  }
  @media ${({ theme }) => theme.breakpoints.small_height} {
    font-family: Sequel45;
    font-size: 1.8rem;
  }
`;
