import { graphql, useStaticQuery } from "gatsby";
import React from 'react'

import Hero from "../components/whatwebuild/hero/Hero.js";
import Layout from '../components/layout/Layout'
import Seo from '../components/seo'
import PublicHealth from "../components/whatwebuild/public-health/PublicHealth";
import Categories from "../components/whatwebuild/categories/Categories";

const WhatwebuildPage = (props) => {
    const {strapiTechnologies} = useStaticQuery(query);

    return (
        <Layout path={props.location.pathname}>
            <Seo
                title={strapiTechnologies.metaTitle}
                description={strapiTechnologies.metaDescription}
                keywords={strapiTechnologies.metaKeywords}
            />
            <Hero path={props.location.pathname}/>
            <PublicHealth />
            <Categories />
        </Layout>
    )
}

export default WhatwebuildPage

const query = graphql`
    query {
        strapiTechnologies {
            metaTitle
            metaDescription
            metaKeywords
        }
    }
`
